import React, { Component } from 'react';

import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs'
import Badge from 'components/controls/Badge';
import Button from 'components/controls/Button';
import Spacer from 'components/controls/Spacer';
import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';
import Collapsible from 'react-collapsible';
import CardList from 'components/controls/cardlist/CardList'
import Toaster from 'components/util/Toaster';
import { TextArea } from 'components/controls/TextArea';
import { createRightsManagement } from './UserRoleRightsManagement'

import './RoleManagement.scss'

class RoleManagement extends Component {
    lookups = {
        userRoles: [],
        employeeMap: {}
    }

    constructor(props) {
        super(props);

        const module = "UserRole";

        this.state = {
            module: module,
            title: arxs.modules.titles[module],
            icon: arxs.modules.icons[module],
            searchTerm: '',
            data: {},
            editing: {},
            dirtyValue: null
        }
    };

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups
                .subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    refresh = () => {
        const { userRoles, employeeMap } = this.state;

        if (userRoles && employeeMap) {
            const searchTerm = this.state.searchTerm.toLowerCase();

            const data = userRoles
                .filter(x => !x.isDeleted)
                .filter(x => x.name.toLowerCase().indexOf(searchTerm) !== -1
                    || x.users.some(x => employeeMap[x.id] && employeeMap[x.id].name && employeeMap[x.id].name.toLowerCase().indexOf(searchTerm) !== -1)
                    || x.users.some(x => employeeMap[x.id] && employeeMap[x.id].userName && employeeMap[x.id].userName.toLowerCase().indexOf(searchTerm) !== -1))
                .map(role => ({ ...role, users: role.users
                    .filter(x => !x.readOnly)
                    .filter(x => employeeMap[x.id] && employeeMap[x.id].name)
                    .filter(x =>
                            (employeeMap[x.id].name.toLowerCase().indexOf(searchTerm) !== -1)
                            || (employeeMap[x.id].userName.toLowerCase().indexOf(searchTerm) !== -1)
                    )
                    .orderBy(x => employeeMap[x.id].name.toLowerCase())
                }))
                .orderBy(x => x.name);

            this.setState({ data });
        }
    }

    handleChangeSearchTerm = (event) => {
        this.setState({ searchTerm: event.target.value }, this.refresh);
    }

    addNewRole = (context) => {
        const executeAction = (name) => {
            if (this.state.userRoles.some(x => x.name.toLowerCase().replace(/\s/g, '') === name.toLowerCase().replace(/\s/g, ''))) {
                Toaster.error(arxs.t("actions.userrole.duplicate_name"));
            } else {
                arxs.ApiClient.masterdata.userRole.add(name)
                    .then(() => Toaster.success(arxs.t("actions.userrole.role_added")));
            }
        };

        const inputPopup = createInputPopup(context,
            arxs.t("actions.userrole.add_new"),
            (name) => executeAction(name),
            true,
            true,
            arxs.t("role_management.name"),
            arxs.t("common.next"),
            arxs.t("common.cancel"),
            false);

        context.inputPopup.show(inputPopup);
    }

    removeRole = (event, state) => {
        const context = state.context;
        event.stopPropagation();
        const executeAction = () => {
            arxs.ApiClient.masterdata.userRole.delete(state.id)
                .then(() => Toaster.success(arxs.t("actions.userrole.delete_confirmation")));
        }

        const confirmation = createInputPopup(context, arxs.t("actions.userrole.delete_confirmation_question"), () => executeAction());

        context.inputPopup.show(confirmation);

    }

    renameRole = (event) => {
        const editingId = this.state.editing;
        const { dirtyValue } = this.state;

        if (this.state.userRoles.some(x => x.name.toLowerCase().replace(/\s/g, '') === dirtyValue.toLowerCase().replace(/\s/g, '')
            && x.id !== editingId)) {
            // We have a duplicate name for another role
            Toaster.error(arxs.t("actions.userrole.duplicate_name"));
        } else if (this.state.userRoles.some(x => x.name.toLowerCase().replace(/\s/g, '') !== dirtyValue.toLowerCase().replace(/\s/g, '')
            && x.id === editingId)) {
            // name is changed and unique, we save it.
            arxs.ApiClient.masterdata.userRole.rename(editingId, this.state.dirtyValue)
                .then(this.setState({ editing: {}, dirtyValue: null }))
                .then(() => Toaster.success(arxs.t("actions.userrole.renamed")))
        } else {
            // nothing changed, resetting.
            this.setState({ editing: {}, dirtyValue: null });
        }

    }

    onRenameRole = (event, state) => {
        event.stopPropagation();

        const { data } = this.state;

        const name = data.filter(x => x.id === state.id)[0].name

        this.setState({ editing: state.id, dirtyValue: name });
    }

    editRightsForRole = (event, state) => {
        event.stopPropagation();
        let rightsManagement = createRightsManagement(this.state.userRoles.filter(x => x.id === state.id)[0]);
        this.setState({ rightsManagement }, () => state.context.popup.show(this.state.rightsManagement));
    }

    onChangeUserList = (users, roleId) => {
        const { data } = this.state;
        const dataUsers = data.filter(x => x.id === roleId)[0].users;

        if (dataUsers.length < users.length) {
            const newUsers = users.map(x => x.id).except(dataUsers.map(x => x.id));
            arxs.ApiClient.masterdata.userRole.addUsers(roleId, newUsers)
                .then(() => Toaster.success(arxs.t("actions.userrole.user_added")));
        } else if (dataUsers.length > users.length) {
            const deletedUser = dataUsers.map(x => x.id).except(users.map(x => x.id))[0];
            arxs.ApiClient.masterdata.userRole.deleteUserFromRole(roleId, deletedUser)
                .then(() => Toaster.success(arxs.t("actions.userrole.delete_user_confirmation")))
        }
    }

    onKeyDown = (event) => {
        event.stopPropagation();

        switch (event.keyCode) {
            case 9:
            case 13:
                this.renameRole(event);
                break;
            case 27:
                this.setState({ editing: {}, dirtyValue: null });
                break;
            default: break;
        }
    }

    render() {
        const { data, searchTerm } = this.state;
        const userRoleCount = data && data.length;
        const expand = searchTerm && searchTerm.length > 0;

        const noRoles = (context) => {
            return <div className="rolemanagement-details-empty">
                <div className="rolemanagement-details-empty-icon">
                    <i className="fas fa-user-tag"></i>
                </div>
                <div className="rolemanagement-details-empty-text">
                    {arxs.t("role_management.no_roles")}
                </div>
                <div className="rolemanagement-details-empty-action">
                    <button className="rolemanagement-details-empty-action-create" onClick={() => this.addNewRole(context)}>{arxs.t("kanban.common.new")} <i className="fas fa-plus"></i></button>
                </div>
            </div>
        }

        const renderName = (context, userRole) => {
            const { editing, dirtyValue } = this.state;

            if (editing === userRole.id) {
                const field = {
                    name: 'name',
                    getter: () => dirtyValue,
                    setter: (value) => {
                        this.setState({ dirtyValue: value });
                    }
                };
                return <TextArea
                    autoFocus
                    className='input'
                    field={field}
                    onKeyDown={this.onKeyDown}
                    onBlur={this.onBlur}
                />
            } else {
                return userRole.name
            }
        }

        const roleHeader = (context, userRole) => {
            const tenantId = arxs.Identity.profile.tenant;
            const isEditAllowed = (userRole.name.toLowerCase() !== "admin") && (userRole.tenantId === tenantId);
            return <div className="rolemanagement-details-header">
                <div className="rolemanagement-details-header-left">
                    <div className="rolemanagement-details-header-name">{renderName(context, userRole)}</div>
                    {isEditAllowed && <div className="rolemanagement-details-header-rename" onClick={(event) => this.onRenameRole(event, { context: context, id: userRole.id })} title={arxs.t("actions.userrole.rename")}> <i className="fas fa-pencil"></i></div>}
                    <div className="rolemanagement-details-header-badge"><Badge >{userRole.users.length}</Badge></div>
                </div>
                <div className="rolemanagement-details-header-right">
                    {isEditAllowed && <div className="rolemanagement-details-header-delete" onClick={(event) => this.removeRole(event, { context: context, id: userRole.id })} title={arxs.t("actions.userrole.delete")}> <i className="fas fa-trash-alt"></i></div>}
                    {isEditAllowed && <div className="rolemanagement-details-header-rights" onClick={(event) => this.editRightsForRole(event, { context: context, id: userRole.id })} title={arxs.t("actions.userrole.set_rights")}> <i className="fas fa-cog"></i></div>}
                </div>
            </div >;
        };

        return <GlobalContext.Consumer>
            {
                context => (
                    <div className="rolemanagement">
                        <div className="rolemanagement-header">
                            <div className="rolemanagement-title">{arxs.t("modules.userrole")} <Badge className="red">{userRoleCount}</Badge></div>
                            <div className="rolemanagement-toolbar">
                                {context.platform.isMobile && <div className="rolemanagement-toolbar-icon"><i className={arxs.modules.icons[this.state.module]}></i></div>}
                                <div className="input-wrapper">
                                    <i className="far fa-search"></i>
                                    <input type="text" value={this.state.searchTerm} onChange={this.handleChangeSearchTerm}
                                        placeholder={arxs.t("kanban.search_placeholder", { module: this.state.title.toLowerCase() })} />
                                </div>
                                <Spacer width="10px" />
                                <Button className="create" onClick={() => this.addNewRole(context)}>
                                    {!context.platform.isMobile && arxs.t("kanban.common.new")} <i className="fa fa-plus"></i>
                                </Button>
                            </div>
                        </div>
                        <HorizontalSeparator />
                        <div className="rolemanagement-details">
                            {(!data || data.length === 0) && noRoles(context)}
                            {data && data.length > 0 && data.map((role, index) =>
                                <Collapsible key={role.name} trigger={roleHeader(context, role)} open={expand ? true : false}>
                                    <CardList
                                        className="field"
                                        key={`${role.name}-key`}
                                        value={role.users.map(x => ({
                                            id: x.id,
                                            module: "Employee",
                                            readOnly: role.name.toLowerCase() === "admin" && x.id === context.profile.id
                                        }))}
                                        onChange={(users) => this.onChangeUserList(users, role.id)}
                                        singleSelection={false}
                                        hideTitle={true}
                                        condensed={true}
                                        expandable={true}
                                        modules={["Employee"]} />
                                </Collapsible>)}

                        </div>
                    </div>)
            }
        </GlobalContext.Consumer>
    }
}
export default RoleManagement;