import React, { Component, Fragment } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { List } from 'react-virtualized';
import Button from "components/controls/Button";
import { createInputPopup } from 'components/shell/InputPopup/InputPopup';

import GlobalContext from 'infra/GlobalContext';
import arxs from 'infra/arxs'
import TagTreeview from 'components/controls/tags/TagTreeView'
import { createCardLookup } from 'components/shell/CardLookup/CardLookup';
import { Card } from 'components/card/Card';
import CardDataSource from 'infra/CardDataSource';
import Badge from 'components/controls/Badge';
import Import from 'components/settings/Import';
import { OriginModuleEnum } from 'infra/api/contracts';

import './TagManagement.scss'



const _supportedModules = [
    OriginModuleEnum.SchoolGroup,
    OriginModuleEnum.School,
    OriginModuleEnum.Building,
    OriginModuleEnum.Room,
    OriginModuleEnum.EquipmentInstallation,
    OriginModuleEnum.Labourmeans,
    OriginModuleEnum.Pbm,
    OriginModuleEnum.HazardousSubstance,
    OriginModuleEnum.IntangibleAsset,
    OriginModuleEnum.CombinedInstallation,
    OriginModuleEnum.Document,
    OriginModuleEnum.Commissioning,
    OriginModuleEnum.OutOfCommissioning,
    OriginModuleEnum.SafetyInstructionCard,
    OriginModuleEnum.Consultancy,
    OriginModuleEnum.Task,
    OriginModuleEnum.NotificationDefect,
    OriginModuleEnum.ActivityEntry,
    OriginModuleEnum.Project,
    OriginModuleEnum.PeriodicMaintenance,
    OriginModuleEnum.PeriodicControl,
    OriginModuleEnum.Periodical,
    OriginModuleEnum.RiskAnalysis,
    OriginModuleEnum.GlobalPreventionPlan,
    OriginModuleEnum.IncidentManagement,
    OriginModuleEnum.Form,
    OriginModuleEnum.Training,
    OriginModuleEnum.Employee,
    OriginModuleEnum.Supplier,
    OriginModuleEnum.Contact,
];

class TagManagement extends Component {
    lookups = {
        legalStructureMap: {},
        branchMap: {},
        buildingMap: {},
        locationMap: {},
        codeElementsById: {},
        labourmeanMap: {},
        equipmentMap: {},
        taskMap: {},
        taskRequestMap: {},
        periodicalMap: {},
        employeeMap: {}
    }

    constructor(props) {
        super(props);

        const criteria = {
            "searchTerm": () => this.state.searchTerm,
            "sort": () => this.state.sortSelectedMap,
            "kind": () => this.state.kindSelectedMap,
            "type": () => this.state.typeSelectedMap,
            "tags": () => this.state.tagMap,
            "modules": () => _supportedModules.toDictionary(x => x, x => x),
        };

        this.state = {
            selectedItem: {},
            items: [],
            cards: [],
            tagMap: {},
            cardWidth: 310,
            dataSource: new CardDataSource(criteria),
            checkedItems: [],
            importResult: []
        }
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
        if (this.state.dataSource) {
            this.state.dataSource.dispose();
        }
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups.subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
        this.state.dataSource.setRefresh(this.refresh);
    }

    refresh = () => {
        const cards = this.state.dataSource.get();
        this.setState({ cards });
    }

    onTreeViewItemClick = (item) => {
        if (item) {
            if (item.type === arxs.codeElementTypes.kind
                || item.type === arxs.codeElementTypes.type
                || item.type === arxs.codeElementTypes.item) {
                this.setState({
                    selectedItem: item,
                    cards: [],
                    importResult: [], // Reset errors
                    importKey: Date.now(), // Forces re-render of Import component
                });
            }
    
            if (item.type === arxs.codeElementTypes.item) {
                this.setState({ tagMap: { [item.id]: true } }, this.refresh);
            }
        } else {
            this.setState({
                selectedItem: null,
                cards: [],
                importResult: [],
                importKey: Date.now(),
            });
        }
    };

    onTreeViewItemEdit = (item) => {
        this.setState({ selectedItem: item });
    }

    addNewTag = (context) => {

    }

    selectCard = (event, card) => {
        if (!card) {
            return
        }

        this.setState({ selected: card });

        this.context.detailsPane.open(card);
    }

    onToggle = (event, card) => {
        let checked = this.state.checkedItems;
        if (checked[card.id]) {
            delete checked[card.id];
        } else {
            checked[card.id] = true;
        }

        this.setState({ checkedItems: checked });
    }

    getBreadCrumbParts = () => {
        const traverse = (item) => item ? [...traverse(item.parent), item.name] : [];
        return traverse(this.state.selectedItem);
    }

    handleTreeViewLoad = (items) => {
        this.setState({ items });
    }

    addCard = (context) => {
        let cardLookup = this.state.cardLookup;
        if (!cardLookup) {
            const onApplyFilter = state => {
                this.handleAddCards(state);
                context.popup.close();

            }
            const securityContext = arxs.securityContext.buildForUserContext();
            cardLookup = createCardLookup({
                onApplyFilter,
                securityContext
            });
            this.setState({ cardLookup }, () => context.popup.show(this.state.cardLookup));
        } else {
            context.popup.show(this.state.cardLookup);
        }
    }

    handleAddCards = async (cardsToAdd) => {
        let { cards } = this.state;

        var objectRequests = [];

        for (var key in cardsToAdd) {
            const entry = { objectId: key, module: cardsToAdd[key] };
            objectRequests.push(entry);
        }

        if (objectRequests.length > 0) {
            const newCards = objectRequests.map((card, j) => {
                let cardKey = `card-${j}`;
                return {
                    id: card.objectId,
                    key: cardKey,
                    module: card.module,
                };

            }).orderByDescending(card => card.createdAt);

            const allCards = cards.concat(newCards);

            const objects = newCards.map((x) => { return { objectId: x.id, module: x.module } });

            arxs.ApiClient.shared.tags.addObjectsToTag({ tagId: this.state.selectedItem.id, objects: objects }).then(this.setState({ cards: allCards }));
        };
    }

    handleChangeSearchTerm = (event) => {
        const searchTerm = event.target.value;
        this.setState({ searchTerm }, this.refresh);
    }

    getCheckedCards = () => {
        const { checkedItems } = this.state;
        const checkedCards = Object.keys(checkedItems)
        return checkedCards;
    };

    clearCheckedCards = () => {
        this.setState({ checkedItems: {} })
    };

    handleTagBulkRemove = (context) => {
        const { cards, checkedItems } = this.state;
        const selectedCards = cards.filter(x => Object.keys(checkedItems).includes(x.id));

        const handle = () => {
            arxs.ApiClient.shared.tags.deleteTagFromObjects(this.state.selectedItem.id, selectedCards.map(x => ({ objectId: x.id, module: x.module })))
                .then(this.setState({ checkedItems: {} }));

        };

        const popup = createInputPopup(context
            , arxs.t("tag_management.actions.confirm_remove_tag_from_record", { count: selectedCards.length })
            , handle
        );
        context.inputPopup.show(popup);
    }

    renderOperations = (context) => {
        const cards = this.getCheckedCards();
        const count = cards.length;

        const canRemove = this.isAssignAllowed(context);

        return (
            !!count && (
                <div className="tagmanagement-action-toolbar">
                    <div className="section">
                        <i className="far fa-times" onClick={this.clearCheckedCards}></i>
                    </div>
                    <div className="section">
                        <Badge>{count}</Badge>
                        {!context.platform.isMobile && arxs.t("tag_management.cards_selected")}
                    </div>
                    {canRemove && (
                        <Button
                            className="section"
                            onClick={() =>
                                this.handleTagBulkRemove(context, cards)
                            }
                            title={arxs.t("tag_management.remove_tags")}
                        >
                            <i className="far fa-archive"></i>
                        </Button>
                    )}
                </div>))
    }

    isAssignAllowed = (context) => {
        return arxs.isActionAllowed("Tag.Assign");
    }

    render() {
        const { selectedItem, items, cards, cardWidth, selected } = this.state;



        const noKinds = (context) => {
            const noKindText = () => {
                if (items === undefined || items === null || items.length === 0) {
                    return <div className="tagmanagement-content-empty-text">
                        {arxs.t("tag_management.no_sorts")}
                    </div>
                }
            }

            return <div className="tagmanagement-content-empty">
                <div className="tagmanagement-content-empty-icon">
                    <i className="far fa-folder-tree"></i>
                </div>
                {noKindText()}
            </div>
        }

        const noTypes = (context) => {
            return <div className="tagmanagement-content-empty">
                <div className="tagmanagement-content-empty-icon">
                    <i className="far fa-folder-tree"></i>
                </div>
                <div className="tagmanagement-content-empty-text">
                    {arxs.t("tag_management.no_types")}
                </div>
            </div>
        }

        const noTags = (context) => {
            return <div className="tagmanagement-content-empty">
                <div className="tagmanagement-content-empty-icon">
                    <i className="fas fa-tag"></i>
                </div>
                <div className="tagmanagement-content-empty-text">
                    {arxs.t("tag_management.no_tags")}
                </div>
            </div>
        }

        const noCards = (context) => {
            return <div className="tagmanagement-content-empty">
                <div className="tagmanagement-content-empty-icon">
                    <i className="fas fa-ticket"></i>
                </div>
                <div className="tagmanagement-content-empty-text">
                    {arxs.t("tag_management.no_cards")}
                </div>
                {this.isAssignAllowed(context) && <div className="tagmanagement-content-empty-action">
                    <button className="tagmanagement-content-empty-action-create" onClick={() => this.addCard(context)}>{arxs.t("tag_management.add_cards")} <i className="fas fa-plus"></i></button>
                </div>}
            </div>
        }

        const selectTag = (context) => {
            return <div className="tagmanagement-content-empty">
                <div className="tagmanagement-content-empty-icon">
                    <i className="fas fa-tag"></i>
                </div>
                <div className="tagmanagement-content-empty-text">
                    {arxs.t("tag_management.select_tag")}
                </div>
            </div>
        }

        const getContent = (context) => {
            const { importResult } = this.state;

            if (importResult && Array.isArray(importResult) && importResult.length > 0) {
                return <div className="tagmanagement-content-import">
                    <ul className="validation-summary">
                        {importResult.map((e, i) => <li key={`validation-${i}`}>{e}</li>)}
                    </ul>
                </div>;
            }

            if ((items === undefined || items.length === 0)
                || (selectedItem === undefined || selectedItem === null)) {
                return noKinds(context);
            }

            if (Object.keys(selectedItem).length !== 0) {
                switch (selectedItem.type) {
                    case arxs.codeElementTypes.kind:
                    case arxs.codeElementTypes.type:
                        if ((selectedItem.items === undefined || selectedItem.items === null || selectedItem.items.length === 0)
                            || (selectedItem.items.length === 1 && !Object.keys(arxs.codeElementTypes).contains(selectedItem.items[0].type))) {
                            return selectedItem.type === arxs.codeElementTypes.kind ? noTypes(context) : noTags(context);
                        } else {
                            return selectTag(context);
                        };
                    case arxs.codeElementTypes.item:
                        if (cards === undefined || cards.length === 0) {
                            return noCards(context);
                        } else {
                            return getCards(context);
                        };
                    default: arxs.logger.error("Unsupported codeElementType", selectedItem); return;
                }
            }
        }

        const getCards = (context) => {
            return <div className="tagmanagement-content-cards">
                <AutoSizer>
                    {({ width, height }) => {
                        const slices = cards.partition(Math.max(1, Math.floor(width / cardWidth)));
                        return <List
                            width={width}
                            height={height}
                            rowCount={slices.length}
                            rowHeight={165}
                            rowRenderer={
                                ({ index, isScrolling, key, style }) => {
                                    return <div key={key} style={style}>
                                        <div className="card-row">
                                            {slices[index].map((card, i) => {
                                                return <Card
                                                    key={`card-lookup-${index}-${i}`}
                                                    objectId={card.id}
                                                    module={card.module}
                                                    className={card === selected && "selected"}
                                                    onClick={this.selectCard}
                                                    style={{ height: 146 }}
                                                    showOpenInTab
                                                    showImage
                                                    checked={this.state.checkedItems[card.id]}
                                                    onToggle={this.onToggle}
                                                />
                                            })}
                                        </div>
                                    </div>;
                                }
                            }
                        />;
                    }}
                </AutoSizer>
            </div>;
        };

        const breadCrumb = () => {
            const { cards } = this.state;

            const breadCrumbParts = this.getBreadCrumbParts();

            let path = breadCrumbParts.take(3).join(" > ");
            let tagPart = "";

            if (breadCrumbParts && breadCrumbParts.length === 4) {
                tagPart = <span> {'>'} <span className="selected">{`${breadCrumbParts[3]}`}</span></span>;
            }

            return <div>{path}{tagPart} {path && tagPart && <Badge>{cards.length}</Badge>}</div>;
        }

        const actions = (context) => {
            if (this.isAssignAllowed(context) && selectedItem) {
                if (selectedItem.type === arxs.codeElementTypes.item) {
                    return <Fragment>
                        <button className="tagmanagement-content-empty-action-create" onClick={() => this.addCard(context)}>
                            {arxs.t("tag_management.add_cards")}
                            <i className="fas fa-plus"></i>
                        </button>
                    </Fragment>;
                }
            }
        }

        const isActionAllowed = (context, action) =>
            context.profile.allowedActions[action];

        const renderResult = (errors) => {
            if (this.state.importResult !== errors) {
                this.setState({ importResult: errors });
            }
        }

        const importExport = (context) => {
            if (isActionAllowed(context, "Tag.Write")) {
                return <div className="tagmanagement-import">
                    <Import key={this.state.importKey} module={OriginModuleEnum.Tag} renderResult={renderResult} />
                </div>;
            }
        }

        return <GlobalContext.Consumer>
            {context => (
                <div className="tagmanagement">
                    <div className="tagmanagement-header">
                        <div className="tagmanagement-title">{arxs.t("modules.tagmanagement")}{importExport(context)}</div>
                        <div className="tagmanagement-toolbar">
                            <div className="tagmanagement-toolbar-breadcrumbs">{breadCrumb()}</div>
                            {selectedItem && <div className="tagmanagement-toolbar-search">
                                <div className="input-wrapper">
                                    <i className="far fa-search"></i>
                                    <input
                                        type="text"
                                        value={this.state.searchTerm}
                                        onChange={this.handleChangeSearchTerm}
                                    />
                                </div>
                            </div>}
                            <div className="tagmanagement-content-empty-action">{actions(context)}</div>
                        </div>
                    </div>
                    <div className="tagmanagement-details">
                        <div className="tagmanagement-tree">
                            <TagTreeview
                                includeItems
                                onItemClick={this.onTreeViewItemClick}
                                onItemsLoad={this.handleTreeViewLoad}
                                allowEdit
                                onEditItem={this.onTreeViewItemEdit} />
                        </div>
                        <div className="tagmanagement-content">{getContent(context)}</div>
                    </div>
                    {this.renderOperations(context)}
                </div>)
            }
        </GlobalContext.Consumer>
    }
}
export default TagManagement;
// This allows to use the context inside of the component via this.context
TagManagement.contextType = GlobalContext;